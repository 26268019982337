import axios from "axios";
import { notifyError } from "./notification";

export const fetcher = axios.create({ baseURL: process.env.REACT_APP_API_URL });

let abortController = new AbortController();

fetcher.interceptors.request.use((config) => {
  const accessToken = window.localStorage.getItem("token");

  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  return config;
});

fetcher.interceptors.response.use(
  (response) => response,
  (error) => {
    let errorMessage = "An error occurred during the request.";
    let { status, data } = error.response || {};

    if (data && data.message) {
      errorMessage = data.message;
    }
    if (error.response?.status === 401) {
      abortController.abort();
      abortController = new AbortController();

      notifyError(error.response?.data?.message);

      setTimeout(() => {
        localStorage.removeItem("token");
        window.location.href = window.location.origin + "/auth/sign-in";
      }, 3000);
    }

    return Promise.reject({ status, data, message: errorMessage });
  },
);
