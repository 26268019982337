import React, { createContext, useState } from "react";

export const AuthContext = createContext({
  isSessionValid: false,
  logoutHandler: () => {},
  setLoginToken: () => {},
});

export const AuthProvider = ({ children }) => {
  const [isSessionValid, setIsSessionValid] = useState(false);

  const logoutHandler = () => {
    localStorage.removeItem("token");
    setIsSessionValid(false);
  };

  const setLoginToken = (token) => {
    localStorage.setItem("token", token);
  };

  return <AuthContext.Provider value={{ isSessionValid, logoutHandler, setLoginToken, setIsSessionValid }}>{children}</AuthContext.Provider>;
};
