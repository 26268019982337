import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import UserIcon from "components/Icons/UserIcon";
import SignOutIcon from "components/Icons/SignOutIcon";
import { useAuth } from "utils/hooks";

import { PAGES } from "utils/links/pages";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    transition: "ease-out 0.2s",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      transition: "ease-out 0.2s",
      transform: "translateY(-1px)",
    },
  },
  root: {
    background: `${theme.palette.primary.dark}20`,
    "& .MuiMenu-paper": {
      backgroundColor: theme.palette.primary.dark,
      marginTop: theme.spacing(2),
      border: `1px solid ${theme.palette.text.secondary}`,
    },
  },
  menuText: {
    marginLeft: theme.spacing(2),
  },
}));

const menuList = [
  { label: "Profile", link: PAGES.PROFILE, icon: <UserIcon /> },
  { label: "Sign Out", link: PAGES.SIGN_IN, icon: <SignOutIcon /> },
];

const ProfileMenu = ({ anchorEl, onClose, history }) => {
  const classes = useStyles();
  const { logoutHandler } = useAuth();
  const routeHandler = (link) => () => {
    if (link === PAGES.SIGN_IN) {
      logoutHandler();
    }
    history.push(link);
    onClose();
  };

  return (
    <Menu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      className={classes.root}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {menuList.map((item, index) => (
        <MenuItem key={index} className={classes.menuItem} onClick={routeHandler(item.link)}>
          {item.icon && item.icon}
          <Typography variant="body2" className={classes.menuText}>
            {item.label}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default withRouter(ProfileMenu);
